import { Injectable } from '@angular/core';
import { QueryParamsInterface } from '@app/shared/interfaces/abstract/query.params';
import { IFindLogsBackofficeByCriteriaRequest } from '@shared-global/interfaces/logs-backoffice/interfaces/find-logs-backoffice-by-criteria-request.interface';
import { IFindLogBackofficeByCriteriaItem } from '@shared-global/interfaces/logs-backoffice/interfaces/find-logs-backoffice-by-criteria-response.interface';
import { ILogBackoffice } from '@shared-global/interfaces/logs-backoffice/interfaces/log-backoffice.interface';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';

@Injectable()
export class LogsBackofficeService extends AbstractCrudService<
  ILogBackoffice | IFindLogBackofficeByCriteriaItem,
  IFindLogsBackofficeByCriteriaRequest & QueryParamsInterface
> {
  protected override feature = 'logs-backoffice';
  protected override endPoint = `/logs-backoffice/`;
}
